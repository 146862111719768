/** Dashboard Template
 *
 */
import { useContext } from 'react'

import { IPhaseSummaryData, TUserType } from '@unionco/alaris-app-types'

import { UserRoles } from '@unionco/alaris-utils'

import { IAMContext, TIAMContext } from 'context'

import { DashboardHeader, DashboardLayout, ErrorUI } from 'components'

import BuyerDashboard from './Buyer'
import SellerDashboard from './Seller'

interface IDashboardTemplateProps {
  data: IPhaseSummaryData
  headerMainText: string
}

export const DashboardTemplate: React.FC<IDashboardTemplateProps> = ({
  data: { steps: phaseStepSummaries },
  headerMainText
}) => {
  const {
    IAMData: { companyName, userType }
  } = useContext(IAMContext) as TIAMContext

  /**
   * [@seandepottey]
   * Instead of function could utilize children prop here?
   */
  const dashboardContent = (userType: TUserType) => {
    switch (userType) {
      case UserRoles.BUYER:
        return <BuyerDashboard phaseStepSummaries={phaseStepSummaries} />
      case UserRoles.SELLER:
        return <SellerDashboard phaseStepSummaries={phaseStepSummaries} />
      default:
        return <ErrorUI text='Error loading dashboard.' />
    }
  }

  return (
    <DashboardLayout.Body id='dashboard'>
      <DashboardLayout.Header>
        <DashboardHeader
          eyebrow={companyName}
          headerText={headerMainText}
          pageName='dashboard page'
        />
      </DashboardLayout.Header>
      <DashboardLayout.Main>{dashboardContent(userType)}</DashboardLayout.Main>
    </DashboardLayout.Body>
  )
}

export default DashboardTemplate
