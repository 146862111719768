import { cx } from '@algolia/ui-components-shared'
import { Accordion, AccordionContext } from '@unionco/components'
import { PlusMinusIcon } from '@unionco/svg-icons'
import React, { useContext } from 'react'

import type { TSupportItemData } from '@unionco/alaris-app-types'

import { isAdminTracking } from 'utils/tracking'
import { dropdownToggleTracking } from 'utils/tracking/dropdown'

import { TUserContext, UserContext } from 'context'

import StyledMarkDown from 'components/MarkDown'
import { Video } from 'components/generic'

interface ISupportItemProps {
  index: number
  data: TSupportItemData
}

export const SupportItem: React.FC<ISupportItemProps> = ({
  data: { copy, src, title },
  index
}) => {
  const { itemIsOpen } = useContext(AccordionContext)
  const widthClasses = 'u-w-full u-max-w-[70rem]'
  const { userType } = useContext(UserContext) as TUserContext

  function trackingOnClick() {
    dropdownToggleTracking({
      element_name: title,
      is_admin_key: isAdminTracking(userType)
    })
  }

  return (
    <>
      {index !== 0 && <div className={cx('o-line', widthClasses)} />}
      <Accordion.Item index={index} className={cx(widthClasses)}>
        <Accordion.Button
          className='c-dep-repel'
          index={index}
          locked={false}
          callBack={trackingOnClick}
        >
          <h3 className='o-h3 u-font-bold u-text-secondary-800'>{title}</h3>
          <Accordion.Indicator index={index}>
            <PlusMinusIcon
              className='u-w-7 u-text-primary-500'
              open={!itemIsOpen(index)}
            />
          </Accordion.Indicator>
        </Accordion.Button>
        <Accordion.Drawer index={index}>
          <div className='c-flow u-flow-space--600 u-pt-500 u-text-secondary-800'>
            {src && <Video data={{ src, key: 'ISupportFAQItem' }} videoOnly />}
            <StyledMarkDown content={copy} />
          </div>
        </Accordion.Drawer>
      </Accordion.Item>
    </>
  )
}
