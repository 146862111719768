// import { Accordion, Card } from '@unionco/components'
import { useBreakAtSize } from '@unionco/components'
import { useContext, useEffect, useState } from 'react'

import {
  IBuyerDashboardData,
  IPostAuthGlobalData,
  TPhaseStepSummaryData
} from '@unionco/alaris-app-types'

import { UserRoles, isCompletedPhase } from '@unionco/alaris-utils'
import { cx } from '@unionco/utils'
import { isAdminTracking } from 'utils/tracking'
import { helperTracking } from 'utils/tracking/help'
import { matchesTracking } from 'utils/tracking/matches'

import {
  ConstantsContext,
  IAMContext,
  TIAMContext,
  TUserContext,
  UserContext
} from 'context'

import { useAuthFetch } from 'hooks'

import {
  DealsInProgress,
  ErrorUI,
  FAQSectionUI,
  FirmAtAGlance,
  Loading,
  MatchesAccordion,
  MatchesCard,
  QuestionnaireLauncher,
  StyledButton
} from 'components'

// import { MissedDeals } from 'components/dashboard/MissedDeals'

interface IBuyerDashboard {
  phaseStepSummaries: TPhaseStepSummaryData[]
}

export const BuyerDashboard: React.FC<IBuyerDashboard> = ({
  phaseStepSummaries
}) => {
  const {
    IAMData: {
      companyId,
      progression: { phase }
    }
  } = useContext(IAMContext) as TIAMContext

  const { timelineComplete, userType } = useContext(UserContext) as TUserContext
  const { data, error } = useAuthFetch<IBuyerDashboardData>(
    `/dashboard/buyer?companyId=${companyId}`
  )
  const [matchTrackingComplete, setMatchTrackingComplete] =
    useState<boolean>(false)

  const { aboveBreakPoint } = useBreakAtSize('lg')
  const { contactEmail } = useContext(ConstantsContext) as IPostAuthGlobalData

  useEffect(() => {
    if (matchTrackingComplete || !data || !data.potentialMatches) return
    const {
      potentialMatches: { matches }
    } = data
    if (matches && matches.length > 0) {
      matchesTracking(matches)
      setMatchTrackingComplete(true)
    }
  }, [matchTrackingComplete])

  if (error) return <ErrorUI error={error} />
  if (!data) return <Loading debug='BuyerDashboard' />

  const {
    dealsInProgress,
    faq,
    // missedDeals,
    potentialMatches: { matches, subTitle, title },
    recentlyClosedDeals,
    yourFirm
  } = data

  const sidebarClasses = aboveBreakPoint
    ? 'c-withSidebar c-withSidebar--rtl [--sidebar-gutter:theme(spacing.600)]'
    : null

  function trackingOnClick() {
    helperTracking({
      click_type: 'email',
      is_admin_key: isAdminTracking(userType)
    })
  }

  const isTimelineIncompleteAndPhaseNotCompleted =
    !timelineComplete && !isCompletedPhase(phase)
  const isTimelineCompleteOrPhaseCompleted =
    timelineComplete || isCompletedPhase(phase)

  return (
    <div className='c-flow u-flow-space-inner--600'>
      <FirmAtAGlance data={yourFirm} />
      <div className={cx(sidebarClasses)}>
        <div className='c-flow'>
          {isTimelineIncompleteAndPhaseNotCompleted && (
            <QuestionnaireLauncher phaseStepSummaries={phaseStepSummaries} />
          )}
          {/* Potential Matches Module: */}
          {isTimelineCompleteOrPhaseCompleted && (
            <>
              <MatchesAccordion
                title={title}
                headerContent={
                  matches.length > 0 ? (
                    <p className='o-body-1'>{subTitle}</p>
                  ) : (
                    <p className='o-body-1'>{'You do not have any matches yet'}</p>
                  )
                }
                matches={matches}
                companyType={UserRoles.SELLER}
                itemSettings={{
                  buttonContent: 'revenueAndLocation',
                  drawerContent: 'buyer'
                }}
                initialOpenItems={[0]}
                matchCountAdjustment={0}
              />
              {/* Deals In Progress */}
              <DealsInProgress data={dealsInProgress} />
            </>
          )}
        </div>
        <div className='c-flow'>
          {timelineComplete && (
            <>
              {/* Your Recently Closed Deals */}
              <MatchesCard
                data={{
                  title: 'Top Matches',
                  moduleType: 'TopMatches',
                  items: recentlyClosedDeals.items,
                  key: 'recentlyClosedDeals',
                  container: 0
                }}
                companyType={UserRoles.SELLER}
                title={recentlyClosedDeals.title}
              >
                <div>
                  <p className='o-body-1 u-text-3 u-font-bold'>Your Progress</p>
                  <p className='o-body-1 u-mt-2 u-text-2'>
                    {recentlyClosedDeals?.info?.copy}
                  </p>
                </div>
              </MatchesCard>
              {/* Missed Deals */}
              {/* <MissedDeals data={missedDeals} /> */}
            </>
          )}
          {/* FAQ */}
          {faq && <FAQSectionUI data={faq} />}
          {/* Contact */}
          <div className='c-flow u-flow-space--300 u-mt-700'>
            <div className='c-flow u-flow-space--200'>
              <h3 className='o-h3 u-font-bold'>Have any questions?</h3>
              <p className='o-body-1'>The Alaris Buyer Relations Team is ready to help!</p>
            </div>
            <StyledButton
              type='a'
              theme='secondary'
              themeStyle='outline'
              href={`mailto:${contactEmail}`}
              target='_blank'
              rel='noreferrrer'
              className='u-justify-center'
              onClick={trackingOnClick}
            >
              Contact Alaris
            </StyledButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuyerDashboard
