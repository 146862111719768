/**
 * Matches Tracking util function
 */
import { TMatchesTracking } from '@types'
import { IMatchData } from '@unionco/alaris-app-types'

import { joinArrayByKey } from './exploreMatch'
import { tracking } from './tracking'

type TMatchesTrackingParams = Omit<TMatchesTracking, 'event'>

export const matchesTracking = (data: IMatchData[]): void => {
  if (!data) return

  const trackingData: TMatchesTrackingParams = {
    ecommerce: {
      items: data.map(
        ({ alignments, companyName, matchConfidence }: IMatchData, index) => {
          const aligned_most = alignments[0]
          const aligned_least = alignments[1]
          const topAlignedList = joinArrayByKey(aligned_most, 'title')
          const leastAlignedList = joinArrayByKey(aligned_least, 'title')

          return {
            item_name: companyName,
            index: `${index}`,
            item_category: `${matchConfidence}%`,
            item_category2: topAlignedList,
            item_category3: leastAlignedList
          }
        }
      )
    }
  }

  tracking<TMatchesTracking>({
    event: 'view_items_list',
    ...trackingData
  })
}
