import { Accordion } from '@unionco/components'
import { useContext } from 'react'

import {
  IPostAuthGlobalData,
  ISupportSectionData
} from '@unionco/alaris-app-types'

import { ConstantsContext } from 'context'

import ContactLink from 'components/ContactLink'
import { SidebarContext } from 'components/SidebarTemplate'
import { ModuleCard } from 'components/layout'

import { SupportItem } from './item'

interface ISupportProps {
  data: ISupportSectionData
}

export const Support: React.FC<ISupportProps> = ({ data }) => {
  const { contactEmail } = useContext(ConstantsContext) as IPostAuthGlobalData
  const { pageLocations } = useContext(SidebarContext)

  if (!data.segments || data.segments.length < 1) return <></>
  const { copy, eyebrow, faqs, title } = data.segments[pageLocations['segment']]
  return (
    <div className='u-flex u-justify-center'>
      <ModuleCard.Body className='u-w-full u-max-w-[64rem] u-p-600 u-pb-800 u-pt-700'>
        <div className='c-flow u-text-center'>
          <p className='o-body-1 o-eyebrow'>{eyebrow}</p>
          <h2 className='o-h2'>{title}</h2>
          <p className='o-body-1'>{copy}</p>
        </div>
        <Accordion.Main
          className='c-flow u-flow-space-inner--600 u-mt-800 u-items-center'
          multipleOpen
        >
          {faqs.map((item, index) => {
            return (
              <SupportItem
                data={{ title: item.title, ...item.faqContent[0] }}
                index={index}
                key={`SupportItem${index}`}
              />
            )
          })}
        </Accordion.Main>
        <div>
          <ContactLink
            data={{
              title: '',
              contactEmail,
              moduleType: 'AlarisRepContact',
              key: 'contactLink',
              container: 1
            }}
          />
        </div>
      </ModuleCard.Body>
    </div>
  )
}
