/** Authentication Template
 *  Used for login and reset password pages
 */
import { useContext } from 'react'

import { PreAuthGlobalsContext } from 'context/PreAuthGlobals'

import { AuthBGUI, ClientLogoMarquee, Logo } from 'components'

interface IAuthenticationTemplate {
  bgPosition?: 'top' | 'center'
  children: React.ReactNode
  contentLayout?: 'default' | 'center'
}

export const AuthenticationTemplate: React.FC<IAuthenticationTemplate> = ({
  bgPosition,
  children,
  contentLayout = 'default'
}) => {
  const { logos } = useContext(PreAuthGlobalsContext)
  const defaultLayout = !!logos ? 'u-justify-between' : 'u-justify-start'
  const layoutClass =
    contentLayout === 'center' ? 'u-justify-center' : defaultLayout

  return (
    <AuthBGUI bgPosition={bgPosition}>
      <div
        className={`c-box c-flow | u-relative u-min-h-screen u-items-center u-pt-200 md:u-pt-400 lg:u-pt-500 xl:u-pb-800 xl:u-pt-800 ${layoutClass}`}
      >
        <div className='u-flex u-w-full u-flex-grow u-items-center u-justify-center'>
          <div className='c-flow u-w-full u-flex-grow u-items-center'>
            <div className='u-px-600 u-pb-600 u-pt-700'>
              <Logo />
            </div>
            {children}
          </div>
        </div>
        <ClientLogoMarquee />
      </div>
    </AuthBGUI>
  )
}

export default AuthenticationTemplate
