import React, { MouseEvent, useContext, useState, useEffect } from 'react'

import type {
  IContactLinkData,
  IPostAuthGlobalData
} from '@unionco/alaris-app-types'

import {
  TBuyerProfileTrackingPageName,
  TSellerProfileTrackingPageName
} from '@appConstants'

import { ConstantsContext, IAMContext, TIAMContext, TUserContext, UserContext } from 'context'
import { contentDownloadTracking } from 'utils/tracking/contentDownload'
import { isAdminTracking } from 'utils/tracking'

import {
  BackButton,
  DashboardCompanyDetails,
  DashboardHeader,
  DashboardLayout,
  ModulesLayoutSideBar,
  TModuleData
} from 'components'

import { ProfileTemplateContext } from './context'
import { set } from 'cypress/types/lodash'

export interface TProfileTrackingData {
  aum_total: string
  custodians: string // TCommaList
  fee_model: string
}

export interface IProfileTemplateData {
  companyName: string
  companyLogo: string
  companyWebsite: string
  companyLocation: string
  headerMainText: string
  modules: TModuleData[]
  trackingData: TProfileTrackingData
}

export interface IProfileTemplateProps {
  data: IProfileTemplateData
  id: string
  pageName: TBuyerProfileTrackingPageName | TSellerProfileTrackingPageName
}

// TODO: Add main tag somewhere in template, need to determine where main is for this template
export const ProfileTemplate: React.FC<IProfileTemplateProps> = ({
  data: { companyName, companyLogo, companyWebsite, companyLocation, headerMainText, modules },
  id,
  pageName
}) => {
  const { contactEmail } = useContext(ConstantsContext) as IPostAuthGlobalData
  const {
    IAMData: { companyName: userCompanyName }
  } = useContext(IAMContext) as TIAMContext
  const contactData = {
    contactEmail,
    moduleType: 'AlarisRepContact'
  } as IContactLinkData

  const mods = [...modules, contactData]

  const { userType } = useContext(UserContext) as TUserContext
  const [print, setPrint] = useState(false)

  function trackingOnClick(e: MouseEvent<HTMLElement>) {
    const id = e.currentTarget.id
    contentDownloadTracking({
      element_name: document.title + ' Print Page',
      company_name: companyName ? companyName : 'Company Name',
      element_id: id ? id : 'unknown ID',
      is_admin_key: isAdminTracking(userType)
    })
  }

  function handleClick(e: MouseEvent<HTMLElement>) {
    setPrint(true)
    trackingOnClick(e)
  }

  useEffect(() => {
    if (print) {
      window.print();
      setTimeout(() => { setPrint(false) }, 2000);
    }
  }, [print]);


  return (
    <ProfileTemplateContext.Provider value={{ companyName }}>
      <div className='u-bg-gradient-lens-image u-bg-cover u-bg-center u-bg-blend-overlay'>
        <DashboardLayout.Body id={id}>
          <DashboardLayout.Header className='u-border-2 print:u-top-0' useNewStyling={true}>
            <DashboardHeader
              eyebrow={userCompanyName}
              headerText={headerMainText}
              pageName={pageName}
              useNewStyling={true}
            />
          </DashboardLayout.Header>
          <DashboardLayout.Main useNewStyling={true}>
            <div className='u-flex u-justify-between u-mb-4'>
              <BackButton className='lens-overline-text !u-text-black' content={'Back'} />
            </div>
            <DashboardCompanyDetails
              companyLogo={companyLogo}
              companyName={companyName}
              companyLocation={companyLocation}
              companyWebsite={companyWebsite}
            />
            <ModulesLayoutSideBar modules={mods} />
          </DashboardLayout.Main>
        </DashboardLayout.Body>
      </div>
    </ProfileTemplateContext.Provider>
  )
}

export default ProfileTemplate
