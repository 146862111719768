import { useContext } from 'react'

import { ILockedCardData } from '@unionco/alaris-app-types'

import { IAMContext, TIAMContext } from 'context'

import { useAuthFetch } from 'hooks'

import { Loading } from 'components/Loading'

import PotentialMatches, {
  TSellerPotentialMatchesData
} from '../PotentialMatches'
import ValuationEstimate from '../ValuationEstimate'

interface ISellerUnlockableCardsProps {
  data: {
    potentialMatchesData: TSellerPotentialMatchesData
    valuationEstimateData: ILockedCardData
  }
}

export interface IPhaseKeyData {
  index: number
  key: string
  title: string
}

export const SellerUnlockableCards: React.FC<ISellerUnlockableCardsProps> = ({
  data: { potentialMatchesData, valuationEstimateData }
}) => {
  const {
    IAMData: { companyId }
  } = useContext(IAMContext) as TIAMContext
  const { data, error } = useAuthFetch<IPhaseKeyData[]>(
    `/phases?companyType=seller&companyId=${companyId}`
  )

  if (error) return <></>
  if (!data) return <Loading debug='SellerUnlockableCards' />

  return (
    <>
      {potentialMatchesData && (
        <PotentialMatches data={potentialMatchesData} phaseData={data} />
      )}
      {valuationEstimateData && (
        <ValuationEstimate data={valuationEstimateData} />
      )}
    </>
  )
}
