/** Sidebar Template
 *  The sidebar template is defined by the use of the wayfinder navigation
 *  along with a main content area
 *
 *  Used for both the phase style pages and the support page
 */
import { ParsedUrlQuery } from 'querystring'
import { useEffect, useState } from 'react'

import type {
  IFAQSectionData,
  ILocationsIndexesData,
  IPhaseSummaryData,
  IQuestionnaireData,
  ISupportPageSummariesData,
  ISupportSectionData,
  TPhaseStepData,
  TSidebarContentType,
  TSidebarPageSumamryData,
  TSidebarSectionData
} from '@unionco/alaris-app-types'

import {
  TBuyerQuestionnaireTrackingPageName,
  TSellerQuestionnaireTrackingPageName,
  TSupportPageTrackingName
} from '@appConstants'

import { isPhaseView } from './utils'
import { getIndexFromKey } from '@unionco/alaris-utils'
import { getCurrentQuestionData } from 'components/questionnaire/util'
import { getCurrentSegmentData } from 'utils'

import {
  FAQSection,
  ISidebarContext,
  Loading,
  Questionnaire,
  SidebarContext,
  SidebarHeader,
  Wayfinder
} from 'components'
import { Support } from 'components/Support'

const initLocation = {
  section: 0,
  segment: 0,
  question: 0
} as ILocationsIndexesData

export type TSidebarTemplateProps = {
  id: string
  faq?: IFAQSectionData
  query: ParsedUrlQuery
  refetch?: () => void
} & (
    | {
      contentType: 'phaseSteps'
      data: TPhaseStepData | undefined
      pageName?: undefined
      pageRoute: string
      userProgressionStep: string
      sidebarData: IPhaseSummaryData | undefined
      trackingPageName:
      | TBuyerQuestionnaireTrackingPageName
      | TSellerQuestionnaireTrackingPageName
    }
    | {
      contentType: 'support'
      data: ISupportSectionData | undefined
      pageName: string
      pageRoute: 'support'
      userProgressionStep?: undefined
      sidebarData: ISupportPageSummariesData | undefined
      trackingPageName: TSupportPageTrackingName
    }
  )

export const SidebarTemplate: React.FC<TSidebarTemplateProps> = ({
  contentType,
  data,
  id,
  pageName,
  pageRoute,
  query,
  refetch,
  sidebarData,
  trackingPageName,
  userProgressionStep
}) => {
  const [sectionKey, setSectionKey] = useState<string>('')
  const [pageLocations, setPageLocations] =
    useState<ILocationsIndexesData>(initLocation)
  // It may be worth the time to switch this state over to useReducer
  const [sidebarTemplateData, setSidebarTemplateData] =
    useState<TSidebarSectionData | null>(null)
  const [sidebarTemplateSidebarData, setSidebarTemplateSidebarData] =
    useState<TSidebarPageSumamryData | null>(null)

  /** Check content type
   *
   */
  const sidebarContextData = sidebarData
  const mainContextData = isPhaseView(contentType)
    ? (data as IQuestionnaireData)
    : data

  /** Set Sidebar State Effect
   *  Set's the initial state that's fed into
   *  the sidebar template context
   */
  useEffect(() => {
    if (!mainContextData) return
    setSidebarTemplateData(mainContextData)
  }, [mainContextData])

  /** Set Sidebar Summary Data State Effect
   *
   */
  useEffect(() => {
    if (!sidebarContextData) return
    setSidebarTemplateSidebarData(sidebarContextData)
  }, [sidebarContextData])

  /** Routing Effect
   *  Feeds route query into sidebar state to
   *  access the correct data to display
   */
  useEffect(() => {
    if (!sidebarData) return
    if (query.hasOwnProperty('section')) {
      const { question, section, segment } = query
      if (!section || typeof section !== 'string') return

      /**
       * If section (questionnaire step) changed, clear the main data before updating
       * the page locations
       */
      if (section !== sectionKey) {
        if (!!sectionKey) {
          setSidebarTemplateData(null)
        }
        setSectionKey(section)
      }
      const sectionSummaries = isPhaseView(contentType)
        ? (sidebarData as IPhaseSummaryData).steps
        : (sidebarData as ISupportPageSummariesData).sections
      const sectionIndex = getIndexFromKey(sectionSummaries, section)

      const newSection = Number(sectionIndex)
      const newSeg = Number(segment)
      const newQ = Number.isNaN(Number(question)) ? null : Number(question)

      setPageLocations({
        section: typeof newSection === 'number' ? newSection : 0,
        segment: typeof newSeg === 'number' ? newSeg : 0,
        question: newQ
      })
    }
  }, [contentType, query, sectionKey, sidebarData])

  // Display loading component until state is set
  if (
    !sidebarTemplateData ||
    !sidebarTemplateSidebarData ||
    (isPhaseView(contentType) && !sectionKey) ||
    !pageLocations
  )
    return <Loading debug='SidebarTemplate' />

  const initSidebarContext =
    contentType === 'phaseSteps'
      ? ({
        data: sidebarTemplateData,
        pageLocations,
        isPhaseView: isPhaseView(contentType),
        pageName,
        pageRoute,
        sectionProgressIndex: (
          sidebarTemplateSidebarData as IPhaseSummaryData
        ).steps.findIndex((item) => item.key === userProgressionStep),
        setSidebarTemplateData,
        sidebarData: sidebarTemplateSidebarData,
        setSidebarTemplateSidebarData,
        refetch
      } as ISidebarContext)
      : ({
        data: sidebarTemplateData,
        pageLocations,
        isPhaseView: false,
        pageName,
        pageRoute,
        sectionProgressIndex: 0,
        setSidebarTemplateData,
        sidebarData: sidebarTemplateSidebarData,
        setSidebarTemplateSidebarData,
        refetch
      } as ISidebarContext)

  const pageContent = (contentType: TSidebarContentType) => {
    switch (contentType) {
      case 'phaseSteps':
        return (
          <Questionnaire
            questionnaireData={sidebarTemplateData as IQuestionnaireData}
          />
        )
      case 'support':
        return <Support data={sidebarTemplateData as ISupportSectionData} />
      default:
        return <></>
    }
  }

  // TODO: add support key
  const getFAQKey = () => {
    if (contentType === 'phaseSteps') {
      // Check if current question
      const currentQuestion = getCurrentQuestionData(
        pageLocations,
        sidebarTemplateData as IQuestionnaireData
      )
      if (currentQuestion) {
        return { key: currentQuestion.faqGroupKey, type: 'question' }
      } else {
        const currentSegment = getCurrentSegmentData(
          pageLocations,
          sidebarTemplateData as IQuestionnaireData
        )
        return { key: currentSegment.faqGroupKey, type: 'segment' }
      }
    } else {
      return null
    }
  }
  const faqData: { key: string; type: string } | null = getFAQKey()
  const topPadding = 'u-pt-700'

  return (
    <SidebarContext.Provider value={initSidebarContext}>
      <div
        id={id}
        className='t-SidebarTemplate | c-withSidebar c-withSidebar--ltr'
      >
        <Wayfinder />
        <div className='t-SidebarTemplate__body | u-bg-gradient-blue-purple u-px-400 u-py-700 md:u-px-500 lg:u-px-600 xl:u-px-800'>
          <div className='u-w-full'>
            <SidebarHeader
              currentSectionData={sidebarTemplateData}
              pageName={trackingPageName}
            />
            <div className='t-SidebarTemplate__content | c-withSidebar c-withSidebar--rtl'>
              <main className={topPadding}>{pageContent(contentType)}</main>
              {faqData && (
                <div className={topPadding}>
                  <FAQSection
                    relationKey={faqData.key}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </SidebarContext.Provider>
  )
}

export default SidebarTemplate
