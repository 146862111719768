/** Seller Dashboard Template
 *
 */
import { useBreakAtSize } from '@unionco/components'
import { useContext } from 'react'

import {
  ISellerDashboardData,
  TPhaseStepSummaryData
} from '@unionco/alaris-app-types'

import { getProgressionIndexes } from '@unionco/alaris-utils'

import { IAMContext, TIAMContext } from 'context'

import { useAuthFetch } from 'hooks'

import {
  ErrorUI,
  FAQCard,
  Loading,
  QuestionnaireLauncher,
  TimelineProgression
} from 'components'
import { SellerUnlockableCards } from 'components/dashboard/UnlockableCards/UnlockableCards'

interface ISellerDashboard {
  phaseStepSummaries: TPhaseStepSummaryData[]
}

export const SellerDashboard: React.FC<ISellerDashboard> = ({
  phaseStepSummaries
}) => {
  const {
    IAMData: { companyId, id, progression }
  } = useContext(IAMContext) as TIAMContext
  const { data, error } = useAuthFetch<ISellerDashboardData>(
    `/dashboard/seller?userId=${id}&companyId=${companyId}`
  )
  const { aboveBreakPoint } = useBreakAtSize()

  if (error) return <ErrorUI error={error} />
  if (!data) return <Loading debug='SellerDashboard' />

  const { potentialMatchesData, topMatches, valuationEstimateData } = data

  const progressionIndexes = getProgressionIndexes(
    phaseStepSummaries,
    progression
  )
  const { section: stepProgIndex, segment: segmentProgIndex } =
    progressionIndexes

  // Step
  const currentStepSummary = phaseStepSummaries[stepProgIndex]
  const { segments, title: currentStepTitle } = currentStepSummary

  // Segment
  const currentSegment = segments[segmentProgIndex]

  /**
   * Modules
   */
  const questionnaireLauncher = (
    <QuestionnaireLauncher phaseStepSummaries={phaseStepSummaries} />
  )
  const faqMod = <FAQCard relationKey={currentSegment.key} />
  const unlockableCardsMod = (
    <SellerUnlockableCards
      data={{
        potentialMatchesData: { ...potentialMatchesData, matches: topMatches },
        valuationEstimateData
      }}
    />
  )

  return (
    <>
      <TimelineProgression
        currentPhaseName={currentStepTitle}
        currentSectionIndex={stepProgIndex}
        phaseStepSummaries={phaseStepSummaries}
      />
      {aboveBreakPoint ? (
        <>
          <div className='c-withSidebar c-withSidebar--rtl'>
            <div className='c-flow'>
              {questionnaireLauncher}
              {faqMod}
            </div>
            <div className='c-flow'>{unlockableCardsMod}</div>
          </div>
        </>
      ) : (
        <>
          {questionnaireLauncher}
          {unlockableCardsMod}
          {faqMod}
        </>
      )}
    </>
  )
}

export default SellerDashboard
